<template>
  <v-container class="ma-0 pa-0">
    <v-alert
      v-if="poll && !poll.enabled_voting"
      type="warning"
      prominent
    >Hlasovanie bolo zastavené</v-alert>

    <v-alert
      v-else-if="questionID && !questions[0].enabled_voting"
      type="warning"
      prominent
    >Hlasovanie bolo zastavené</v-alert>

    <v-btn
      elevation="2"
      fab
      absolute
      small
      @click="$router.go(-1)"
      v-show="!$vuetify.breakpoint.smAndDown"
    ><v-icon color="primary">mdi-arrow-left-bold</v-icon></v-btn>

    <div v-if="!questionID">
      <v-row dense>
        <v-col align="center">
          <span class="text-h4">
            {{ category.name }}
          </span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align="center">
          <span class="text-subtitle">
            {{ category.sub_name }}
          </span>
        </v-col>
      </v-row>
      <v-row
        dense
        justify="space-between"
      >
        <v-col
          align="center"
          cols="1"
        >
        </v-col>

        <v-col
          align="center"
          cols="10"
        >
          <span class="text-h5 primary--text font-weight-medium">{{ poll.name }}</span>
        </v-col>
        <v-col
          align="right"
          align-self="right"
          cols="1"
        >
          <share-component
            :name="category.name"
            :pageUrl="pageUrl"
          ></share-component>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="space-between"
      >
        <v-col
          cols="12"
          md="4"
          lg="5"
        >
          <v-text-field
            v-model="search"
            label="Vyhľadaj"
            clearable
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="5"
          lg="5"
          class="pl-md-10"
          align="left"
        >
          Zoradiť podľa
          <v-btn
            text
            color="primary"
            class="pa-0 ma-0"
            @click="changeSortYes()"
          >
            ÁNO<div v-show="sort == 'rank_yes' || sort == '-rank_yes'">
              <v-icon v-if="sort == '-rank_yes'">mdi-arrow-down</v-icon><v-icon v-else-if="sort == 'rank_yes'">mdi-arrow-up</v-icon>
            </div>
          </v-btn>

          <v-btn
            text
            color="primary"
            class="pa-0 ma-0"
            @click="changeSortNo()"
          >
            NIE<div v-show="sort == 'rank_no' || sort == '-rank_no'">
              <v-icon v-if="sort == '-rank_no'">mdi-arrow-down</v-icon><v-icon v-else-if="sort == 'rank_no'">mdi-arrow-up</v-icon>
            </div>
          </v-btn>

          <v-btn
            text
            color="primary"
            class="pa-0 ma-0"
            @click="changeSortAll()"
          >
            Spolu<div v-show="sort == 'hodnotenie' || sort == '-hodnotenie'">
              <v-icon v-if="sort == '-hodnotenie'">mdi-arrow-down</v-icon><v-icon v-else-if="sort == 'hodnotenie'">mdi-arrow-up</v-icon>
            </div>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          md="3"
          lg="2"
          align="right"
        >
          <v-btn
            v-if="filter_favorite"
            text
            color="amber amber-darken-4"
            @click="filter_favorite = false"
          >
            Iba obľúbené &nbsp;<v-icon>mdi-star</v-icon>
          </v-btn>

          <v-btn
            v-else
            text
            color="amber amber-darken-4"
            @click="filter_favorite = true"
          >
            Iba obľúbené &nbsp;<v-icon>mdi-star-outline</v-icon>
          </v-btn>

        </v-col>
      </v-row>

      <v-row
        justify="space-between"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          md="5"
          align="left"
        >
          <v-menu
            ref="menu"
            v-model="dateFilterMenu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Filtrovať podľa dátumu"
                prepend-icon="mdi-calendar"
                append-icon="mdi-close"
                @click:append="dates=[]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              multiple
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dateFilterMenu = false"
              >
                Zmazať
              </v-btn>

              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dates)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="6"
          align="right"
        >
          <v-btn
            v-if="filter_voted"
            text
            @click="filter_voted = false"
          >
            Iba neodhlasované &nbsp;<v-icon>mdi-check</v-icon>
          </v-btn>

          <v-btn
            v-else
            text
            @click="filter_voted = true"
          >
            Iba neodhlasované &nbsp;<v-icon small>mdi-check-outline</v-icon>
          </v-btn>

        </v-col>
      </v-row>
    </div>

    <v-divider v-if="!questionID"></v-divider>

    <v-container
      v-for="question in questions"
      v-bind:key="question.id"
      class="ma-0 pa-0"
      :id="question.id"
    >
      <v-row
        dense
        class="pt-5"
      >
        <v-col
          v-if="!questionID"
          cols="1"
          align="left"
          class="text-caption"
        >
          #{{ question.order }}
        </v-col>

        <v-col
          align="center"
          class="text-h6"
        >
          <poll-question-click-desc
            :name="question.first_field"
            :description="question.description_click"
          ></poll-question-click-desc>
        </v-col>

        <v-col
          align="right"
          cols="1"
        >
          <share-component
            :name="question.first_field"
            :pageUrl="pageUrl + '#' + question.id"
          ></share-component>
        </v-col>
      </v-row>
      <v-row
        class="mt-0 pt-0 mb-2 mb-md-0"
        justify="center"
      >
        <v-col cols="auto">
          <poll-description
            v-if="question.description"
            name="Doplnenie témy"
            :description="question.description"
          ></poll-description>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="center"
      >
        <v-col
          v-if="!questionID && question.sub_questions.length > 0"
          cols="auto"
          align="center"
        >
          <v-btn
            text
            small
            :to="'/poll_category/' +categoryID +'/poll_sub_questions/' +question.id + '/'"
          >
            Súvisiace otázky, tézy a tvrdenia ({{ question.sub_questions.length }})
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          align="left"
          cols="12"
          md="6"
        >
          <v-btn
            v-if="question.enabled_discussion"
            text
            color="primary"
            :to="'/poll_question_discussion/' + question.id"
          >
            Vstúpiť do diskusie ({{ question.comments_total }})
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-list-item class="grow">
            Autor:&nbsp;
            <v-list-item-avatar color="grey darken-3">
              <v-img
                v-if="question.created_by.photo"
                class="elevation-6"
                alt=""
                :src="question.created_by.photo"
                style="cursor: pointer"
                @click="selectedItem = question.created_by.photo"
              ></v-img>
              <v-icon
                v-else
                x-large
              >mdi-ab-testing</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ question.created_by.first_name }} {{ question.created_by.last_name }} <br />{{ question.created_by.address_town }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-col>
        <v-col
          v-if="!questionID"
          cols="12"
          md="3"
          align="right"
        >
          <v-btn
            v-if="question.user_favorite"
            icon
            color="amber amber-darken-4"
            @click="removeFavorite(question.id)"
          ><v-icon>mdi-star
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            color="amber amber-darken-4"
            @click="addFavorite(question.id)"
          ><v-icon>mdi-star-outline
            </v-icon>
          </v-btn>

        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col
          v-if="question.type == 'ZRKADLA' || question.type == 'TRIBUNAL'"
          cols="12"
          md="6"
          class="pa-5"
          justify="center"
        >
          <v-img
            v-if="question.photo"
            :src="question.photo"
          ></v-img>

          <div
            v-else-if="question.video"
            class="player-container"
            :autoplay="false"
          >
            <vue-core-video-player
              :src="question.video"
              :autoplay="false"
            ></vue-core-video-player>
          </div>

        </v-col>
        <v-col
          v-else-if="question.type == 'NAZORY'"
          cols="12"
          md="6"
          class="px-3"
          justify="center"
        >
          <poll-nazory :question="question"></poll-nazory>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="pa-5"
        >
          <poll-voting
            name="ÁNO"
            :selected="question.user_voted_type == 'YES_YES'"
            :votes="question.votes_yes_yes_count"
            :totalVotes="question.votes_total_count"
            color="success"
            type="YES_YES"
            :questionID="question.id"
            @sync-child="syncChild"
          > </poll-voting>
          <poll-voting
            v-show="question.type == 'ZRKADLA' || question.type == 'NAZORY'"
            name="Skôr ÁNO"
            :selected="question.user_voted_type == 'YES_NO'"
            :votes="question.votes_yes_no_count"
            :totalVotes="question.votes_total_count"
            color="success lighten-2"
            type="YES_NO"
            :questionID="question.id"
            @sync-child="syncChild"
          > </poll-voting>

          <poll-voting
            name="ZDRŽÍM SA"
            :selected="question.user_voted_type == 'NEUTRAL'"
            :votes="question.votes_neutral_count"
            :totalVotes="question.votes_total_count"
            color="primary"
            type="NEUTRAL"
            :questionID="question.id"
            @sync-child="syncChild"
          > </poll-voting>

          <poll-voting
            v-show="question.type == 'ZRKADLA' || question.type == 'NAZORY'"
            name="Skôr NIE"
            :selected="question.user_voted_type == 'NO_YES'"
            :votes="question.votes_no_yes_count"
            :totalVotes="question.votes_total_count"
            color="error lighten-2"
            type="NO_YES"
            :questionID="question.id"
            @sync-child="syncChild"
          > </poll-voting>

          <poll-voting
            name="NIE"
            :selected="question.user_voted_type == 'NO_NO'"
            :votes="question.votes_no_no_count"
            :totalVotes="question.votes_total_count"
            color="error"
            type="NO_NO"
            :questionID="question.id"
            @sync-child="syncChild"
          > </poll-voting>
        </v-col>
      </v-row>

      <v-row
        v-show="question.type == 'ZRKADLA'"
        align="center"
        justify="center"
        no-gutters
        class="mb-3"
      >

        <v-col
          v-if="!$vuetify.breakpoint.mdAndUp"
          cols="12"
          md="4"
          class="ma-1 ma-sm-0 pa-1"
          align="center"
          align-self="center"
        >
          <span
            v-if="getPercentageAll(question) == 0"
            class="primary--text"
          >Celková dôvera {{ getPercentageAll(question) }}%</span>
          <span
            v-else-if="getPercentageAll(question) > 0"
            class="success--text"
          >Celková dôvera {{ getPercentageAll(question) }}%</span>
          <span
            v-else
            class="error--text"
          >Celková nedôvera {{ Math.abs(getPercentageAll(question)) }}%</span>
        </v-col>

        <v-col
          cols="12"
          md="4"
          align="center"
        >
          Pozícia dôveryhodnosti <strong>{{ question.calculation_reversed? question.rank_no: question.rank_yes }}</strong> <span v-if="question.ranks_total_no > 99">zo</span><span v-else>z</span> {{ question.ranks_total_no }}
        </v-col>

        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="12"
          md="4"
          class="ma-1 ma-sm-0 pa-1"
          align="center"
          align-self="center"
        >
          <span
            v-if="getPercentageAll(question) == 0"
            class="primary--text"
          >Celková dôvera {{ getPercentageAll(question) }}%</span>
          <span
            v-else-if="getPercentageAll(question) > 0"
            class="success--text"
          >Celková dôvera {{ getPercentageAll(question) }}%</span>
          <span
            v-else
            class="error--text"
          >Celková nedôvera {{ Math.abs(getPercentageAll(question)) }}%</span>
        </v-col>

        <v-col
          cols="12"
          md="4"
          align="center"
        >
          Pozícia nedôveryhodnosti <strong>{{ question.calculation_reversed? question.rank_yes: question.rank_no }}</strong> <span v-if="question.ranks_total_no > 99">zo</span><span v-else>z</span> {{ question.ranks_total_no }}
        </v-col>

      </v-row>
      <v-row
        no-gutters
        justify="end"
        class="mr-3 mb-1 mt-0 pl-6"
      >
        <v-col
          cols="12"
          md="6"
          align="left"
        >
          <poll-question-sources
            v-show="question.sources"
            name="Povedali, napísali niekde inde"
            :description="question.sources"
          ></poll-question-sources>
        </v-col>
        <v-col
          cols="12"
          md="3"
          align="right"
          align-self="right"
        > <poll-question-detail :question="question"></poll-question-detail>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-container>
    <v-overlay
      v-if="selectedItem"
      @click="selectedItem = null"
    >
      <v-container>
        <v-row justify="center">
          <v-col align="center">
            <v-img
              max-width="300px"
              max-height="300px"
              :src="selectedItem"
              contain
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>

  </v-container>
</template>
  
<script>
import { mapGetters } from "vuex";

import { APIService } from "@/http/APIService.js";

import PollVoting from "@/components/poll/PollVoting.vue";
import PollNazory from "./PollNazory.vue";
import PollDescription from "./PollDescription.vue";
import PollQuestionDetail from './PollQuestionDetail.vue';
import PollQuestionSources from './PollQuestionSources.vue';
import PollQuestionClickDesc from './PollQuestionClickDesc.vue';

import moment from 'moment';
import ShareComponent from '@/components/ShareComponent.vue';

const apiService = new APIService();

import VueCoreVideoPlayer from 'vue-core-video-player'
import Vue from 'vue';

Vue.use(VueCoreVideoPlayer)

export default {
  name: 'Poll',
  inheritAttrs: true,

  components: {
    PollVoting,
    PollNazory,
    PollDescription,
    PollQuestionDetail,
    PollQuestionSources,
    PollQuestionClickDesc,
    ShareComponent
  },

  props: {
    pollID: { type: Number },
    categoryID: { type: Number },
    questionID: { type: Number },
  },

  data() {
    return {
      loading: true,
      sync: false,
      search: "",
      filters: [],

      questions: [],
      category: {},
      poll: null,
      count: 0,

      sort: "-order",
      filter_favorite: false,
      filter_voted: false,
      dates: [],

      dateFilterMenu: false,
      dialShare: false,
      selectedItem: null,
    }
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender",
      "user_id"
    ]),

    ...mapGetters("settings", ["getSettings"]),

    getMobileClasses() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "";
      } else {
        return "pl-5";
      }
    },


    dateRangeText() {
      if (this.dates.length > 1)
        return `Od ${moment(this.dates[0]).format("DD.MM.YYYY")} do ${moment(this.dates[1]).format("DD.MM.YYYY")}`;
      else
        return "";
    },

    pageUrl() {
      return window.location.origin + this.$route.path;
    }
  },


  events: {},

  watch: {
    loading() {
      if (!this.loading && this.$route.hash) {
        setTimeout(() => {
          const element = document.getElementById(this.$route.hash.replace("#", ""));
          element.scrollIntoView({ block: "center", inline: "nearest" });
        }, 500);
      }
    },
    sync() {
      this.getQuestions();
    },

    filter_favorite() {
      this.getQuestions();
    },

    filter_voted() {
      this.getQuestions();
    },

    dates() {
      if (this.dates.length == 0 || this.dates.length == 2)
        this.getQuestions();
    },


    search() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getQuestions();
      }, 500);
    },

    sort() {
      this.getQuestions();
    },
  },

  mounted: function () {
    this.getQuestions();

    if (!this.questionID) {
      this.getCategory();
      this.getPoll();
    }
  },

  methods: {
    getVotesWithoutNeutral(question) {
      return question.votes_yes_yes_count + 0.5 * question.votes_yes_no_count + question.votes_no_no_count + 0.5 * question.votes_no_yes_count;
    },

    getPercentageAll(question) {
      let tmp = 0;
      if (question.calculation_reversed)
        tmp = (question.votes_no_no_count + 0.5 * question.votes_no_yes_count - question.votes_yes_yes_count - 0.5 * question.votes_yes_no_count) / this.getVotesWithoutNeutral(question) * 100;
      else
        tmp = (question.votes_yes_yes_count + 0.5 * question.votes_yes_no_count - question.votes_no_no_count - 0.5 * question.votes_no_yes_count) / this.getVotesWithoutNeutral(question) * 100;

      return Math.round((tmp ? tmp : 0 + Number.EPSILON) * 100) / 100;
    },

    removeFavorite(id) {
      apiService.generalPollQuestionRemoveFavorite(id).then(() => {
        this.getQuestions();
      });
    },

    addFavorite(id) {
      apiService.generalPollQuestionAddFavorite(id).then(() => {
        this.getQuestions();
      });
    },

    changeSortYes() {
      if (this.sort == "rank_yes")
        this.sort = '-rank_yes';
      else if (this.sort == "-rank_yes")
        this.sort = "-order";
      else
        this.sort = 'rank_yes';
    },

    changeSortNo() {
      if (this.sort == "rank_no")
        this.sort = '-rank_no';
      else if (this.sort == "-rank_no")
        this.sort = "-order";
      else
        this.sort = 'rank_no';
    },

    changeSortAll() {
      if (this.sort == "hodnotenie")
        this.sort = '-hodnotenie';
      else if (this.sort == "-hodnotenie")
        this.sort = "-order";
      else
        this.sort = 'hodnotenie';
    },


    syncChild() {
      this.getQuestions();
    },
    getCategory() {
      apiService.getGeneralPollCategory(this.categoryID).then(response => {
        this.category = response;
      })
    },

    getPoll() {
      apiService.getGeneralPoll(this.pollID).then(response => {
        this.poll = response;
      })
    },


    getQuestions() {
      let limit = 0;
      let offset = 0;
      let filters = [];
      if (this.questionID)
        filters = [['id', this.questionID]];
      else
        filters = [['poll', this.pollID]];

      let sort = [];

      sort.push(this.sort)

      if (this.dates.length == 2) {
        if (this.dates[0] <= this.dates[1]) {
          const start = new Date(this.dates[0]);
          const end = new Date(this.dates[1]);
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);

          filters.push(['start_date', start.toISOString()]);
          filters.push(['end_date', end.toISOString()]);
        } else {
          const start = new Date(this.dates[1]);
          const end = new Date(this.dates[0]);
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);

          filters.push(['start_date', start.toISOString()]);
          filters.push(['end_date', end.toISOString()]);

          let tmp = this.dates[0];
          this.dates[0] = this.dates[1];
          this.dates[1] = tmp;
        }
      }

      if (this.filter_favorite)
        filters.push(['favorite', 1])

      if (this.filter_voted)
        filters.push(['voted', 0])

      apiService.getGeneralPollQuestions(sort, this.search ? this.search : "", limit, offset, filters).then((response) => {
        this.questions = response.results;
        this.count = response.count;
        this.loading = false;
      });
    }
  },
};
</script>